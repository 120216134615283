import { injectable, inject } from "@theia/core/shared/inversify";
import { CommandService } from "@theia/core/lib/common";
import {
  FrontendApplication,
  FrontendApplicationContribution,
} from "@theia/core/lib/browser";
import { EnvVariablesServer } from "@theia/core/lib/common/env-variables";
import { ContextKeyService } from "@theia/core/lib/browser/context-key-service";

import { getAuthSession } from "./services/auth";
import {
  AUTH_URLS,
  DEFAULT_ENVIRONMENT_VALUES,
  ENVIRONMENT_KEYS,
  URLS,
} from "./utils/constants";
import { startHealthUpdate } from "./services/health";
import { replaceParams } from "./utils/utils";
import {
  AuthenticationService,
  AuthenticationSession,
} from "@theia/core/lib/browser/authentication-service";
import { PreferenceService } from "@theia/core/lib/browser/preferences"; 

/**
 * FrontendStartupContribution is a theia front end contribution plugin
 * for more details on theia front end contribution plugin, check the docs for theia plugins.
 *
 * This plugin is used to get the AppSec user information from the code-editor frontend
 * and pass the same to code-editor extensions.
 *
 * If user information is to be used in a new extension, it will have to implement the
 * command similar to appmod-ai-base.signInFromWebFrontend
 */

@injectable()
export class FrontendStartupContribution
  implements FrontendApplicationContribution
{
  @inject(CommandService)
  protected readonly commandService: CommandService;

  @inject(ContextKeyService)
  protected readonly contextKeyService: ContextKeyService;

  @inject(EnvVariablesServer)
  protected readonly envVariablesServer: EnvVariablesServer;

  @inject(AuthenticationService)
  protected readonly authServer: AuthenticationService;

  @inject(PreferenceService)
  protected readonly preferenceService: PreferenceService; // Inject PreferenceService

  constructor() {}

  async getEnvValue(key: string) {
    const envObj = await this.envVariablesServer.getValue(key);
    return envObj ? envObj.value : DEFAULT_ENVIRONMENT_VALUES[key];
  }

  /**
   * This lifecycle hook is called when the application starts
   * This is not used other than for logging purposes.
   * @param app
   */
  async onStart(app: FrontendApplication) {
    // Not sure what this does, probably a Log
    window.postMessage(
      { type: "code-editor-start", payload: "triggered start" },
      "*"
    );
    const path = window.location.pathname;
    const sessionId = path.substring(path.lastIndexOf("/") + 1);

    const codeEditorBaseUrl = await this.getEnvValue(
      ENVIRONMENT_KEYS.codeEditorBaseUrl
    );

    const editorHeathUpdateInterval = await this.getEnvValue(
      ENVIRONMENT_KEYS.editorHeathUpdateInterval
    );

    const editorFocusOutTimeOut = await this.getEnvValue(
      ENVIRONMENT_KEYS.editorFocusOutTimeOut
    );

    const authBaseUrl = await this.getEnvValue(ENVIRONMENT_KEYS.authBaseUrl);

    // Making a call to AppSec to get user credentials
    // This assumes that user is logged in and cookie is available
    const session = await getAuthSession(`${authBaseUrl}${AUTH_URLS.me}`);
    
    this.updateAuthSessionWithPoll(session);

    if (!editorHeathUpdateInterval || !editorFocusOutTimeOut) return;

    const sessionHealthEndpoint = replaceParams(
      `${codeEditorBaseUrl}${URLS.sessionHealth}`,
      {
        sessionId,
      }
    );

    let interval: any;
    let focusOutTimeout: any;

    // Event listener for window focus event
    window.addEventListener("focus", () => {
      clearTimeout(focusOutTimeout);
      interval = startHealthUpdate(
        sessionHealthEndpoint,
        parseInt(editorHeathUpdateInterval)
      );
    });

    // Event listener for window blur event
    window.addEventListener("blur", () => {
      focusOutTimeout = setTimeout(() => {
        clearInterval(interval);
      }, parseInt(editorFocusOutTimeOut));
    });
  }

  /**
   * This lifecycle hook will be called when Layout for this theia plugin is ready.
   * @param app
   */
  async onDidInitializeLayout(app: FrontendApplication) {
    // Not sure what this does, probably a Log
    window.postMessage(
      { type: "code-editor-init-layout", payload: "triggered init layout" },
      "*"
    );
    await this.preferenceService.set("workbench.colorTheme", "Visual Studio Dark");
    /**
     * List of extensions or commands to which user information should be broadcasted
     */

    /**
     * This is a small closure that will wait for the dependant
     * extensions to be loaded and ready, once they are ready
     * it will pass the user details to the extensions.
     */
  }

  async updateAuthSessionWithPoll(session: any) {
    const authProviderId = await this.getEnvValue(
      ENVIRONMENT_KEYS.authProviderId
    );

    const commandsString = await this.getEnvValue(ENVIRONMENT_KEYS.commands);
    const setupBootstrapCommand = await this.getEnvValue(ENVIRONMENT_KEYS.setupBootstrapCommand);

    const extOnActivateTimer = await this.getEnvValue(
      ENVIRONMENT_KEYS.extOnActiveWaitInterval
    );

    if (!extOnActivateTimer || !authProviderId || !commandsString ||!setupBootstrapCommand) return;

    const commands = commandsString.split(",");

    const allCommands = [...commands,setupBootstrapCommand]
    let timer: any;

    const onExtensionsReady = async () => {
   /**
    * Pass the session to the extensions via command to create the auth session
    * We cannot directly authenticate with the auth provider
    * Extension itself has to use it's auth provider to authenticate
    * it's a command that forces the extension to authenticate using session passed from here
    */
      const sessions: AuthenticationSession[] = [
        {
          accessToken: session.accessToken,
          account: {
            id: session._id,
            label: `${session.firstname} ${session.lastname}`,
          },
          scopes: ["read:user"],
          id: Math.random().toString(36).substring(7),
        },
      ];

      commands.forEach((command) => {
        this.commandService.executeCommand(command, sessions);
      });

      this.authServer.onDidChangeSessions(e=>{
        if(e.providerId===authProviderId){
            this.commandService.executeCommand(setupBootstrapCommand);
        }
      })
    };

    const getAuthenticationProviderStatus = () => {
      return Boolean(
        this.authServer.isAuthenticationProviderRegistered(authProviderId) &&
        allCommands.every((command) =>
            (this.commandService as any).getCommand(command)
          )
      );
    };

    const pollExtensionReadyStatus = async () => {
      if (getAuthenticationProviderStatus()) {
        await onExtensionsReady();
      } else {
        clearTimeout(timer);
        timer = setTimeout(() => {
          pollExtensionReadyStatus();
        }, parseInt(extOnActivateTimer));
      }
    };

    pollExtensionReadyStatus();
  }
}
